import Dropdown from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Layout/Loader/Loader';
import { ICON_NAMES } from 'constants/icon';
import { usePostalCodeLocations } from 'features/postalCode/queries';
import { getGeolocationPosition } from 'hooks/useGeolocation';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCityByLatLong } from 'redux/modules/checkout';
import { Country } from 'types/Address';
import { PostalCode } from 'types/PostalCode';
import { createPostalCodeInputOptions } from 'utils/deliveryAddressUtil';
import { log } from 'utils/loggerUtil';
import { formatPostalCode } from 'utils/postalCodeUtil';
import { StyledPostalCodeItem, StyledPostalCodeSelect } from './PostalCodeSelector.styled';

const POSTALCODE_DROPDOWN_ID = 'postal-code-dropdown';

interface PostalCodeSelectorProps {
  className?: string;
  disabled: boolean;
  id: string;
  isChoosingCountry: boolean;
  label?: string;
  name: string;
  onSubmitPostalCode: (value: string, name: string) => void;
  selectedCountry?: Country;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  withIcons: boolean;
}

const PostalCodeSelector = ({
  className,
  disabled,
  id,
  isChoosingCountry,
  label,
  name,
  onSubmitPostalCode,
  selectedCountry,
  selectedValue,
  setSelectedValue,
  withIcons,
}: PostalCodeSelectorProps) => {
  const dispatch = useDispatch();

  const [locations, setLocations] = useState<PostalCode[]>();
  const [loadingState, setLoadingState] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();

  const { data: postalCodes, isFetching: locationLoading } = usePostalCodeLocations({
    countryIsoCode: selectedCountry?.isocode,
    enabled: !!searchTerm?.length,
    searchTerm,
  });

  const postalCodeInputRef = useRef<HTMLInputElement>(null);

  const postalCodeOptions = useMemo(() => createPostalCodeInputOptions(locations), [locations]);

  const setPostalCodeValue = (value: string) => {
    setSelectedValue(value);
    onSubmitPostalCode(value, name);
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedValue(value);

    if (value?.length >= 2) {
      setSearchTerm(value);
    }

    return null;
  };

  useEffect(() => {
    setLocations(postalCodes);
  }, [postalCodes]);

  const handleSearchIconClick = async () => {
    setLoadingState(true);
    const { latitude, longitude } = (await getGeolocationPosition(true)) ?? {};

    try {
      if (!latitude || !longitude) throw new Error('No latitude or longitude found');
      // @ts-ignore redux types
      const { data } = await dispatch(getCityByLatLong(latitude.toString(), longitude.toString()));
      const { name: geoName, postalCode: geoPostalCode } = data;
      const newGeoPostalCode = formatPostalCode(geoName, geoPostalCode);

      setPostalCodeValue(newGeoPostalCode);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      log('GeoLocation', 'Something went wrong with fetching in geolocation', error);
    }
  };

  return (
    <Dropdown
      id={`${POSTALCODE_DROPDOWN_ID}_${id}`}
      disableOutsideClickDetection={isChoosingCountry}
      items={postalCodeOptions?.map((option) => ({
        node: <StyledPostalCodeItem>{option?.value}</StyledPostalCodeItem>,
        value: option?.value,
      }))}
      onCloseDropdownCallback={() => setLocations(undefined)}
      onSubmitValue={(value) => setPostalCodeValue(value)}
      parent={
        <StyledPostalCodeSelect
          ref={postalCodeInputRef}
          appendedContent={
            <>
              {(locationLoading || loadingState) && <Loader />}
              {withIcons && (
                <div onClick={handleSearchIconClick} role="button" tabIndex={0}>
                  <Icon type="custom" name={ICON_NAMES.MAP_MARKER_ALT} size={125} />
                </div>
              )}
            </>
          }
          className={className}
          disabled={disabled}
          handleChange={(event) => handleChange(event)}
          id={`${id}_postalCodeSelect_formGroup`}
          label={label}
          name={name}
          value={selectedValue}
        />
      }
    />
  );
};

export default PostalCodeSelector;
