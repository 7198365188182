import styled, { css } from 'styled-components';
import { color, spacer, variable } from 'theme';
import FormGroupInput from '../Form/FormGroupInput/FormGroupInput';
import Icon from '../Icon/Icon';

export const DeliveryPostalCodeItem = styled.span`
  padding: ${spacer(100)};
  display: block;

  .dropdown-item-active & {
    background-color: ${color('primary-400')};
    color: ${color('white')};
  }
`;

export const StyledDeliveryPostalCodeWrapper = styled.div`
  position: relative;

  .formGroup.with-countries .formGroup-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  transition: all 250ms ease-in-out;
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1;
`;

const activeStyling = css`
  &::before {
    opacity: 30%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${StyledIcon} {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

interface DeliveryPostalCodeCountrySelectorProps {
  active: boolean;
  disabled: boolean;
  empty: boolean;
}

export const DeliveryPostalCodeCountrySelector = styled.div<DeliveryPostalCodeCountrySelectorProps>`
  cursor: default;
  ${variable('border')};
  border-right: none;
  border-radius: ${variable('border-radius')} 0 0 ${variable('border-radius')};
  background-color: ${color('white')};
  padding: 0 ${spacer(75)};
  display: grid;
  align-content: center;
  margin-bottom: ${spacer(75)};
  height: 58px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${color('whisper-200')};
      ${variable('border-right')};
      border-right-color: ${color('whisper-300')};
    `}

  ${({ empty }) =>
    !empty &&
    css<DeliveryPostalCodeCountrySelectorProps>`
      position: relative;
      cursor: pointer;

      &::before {
        transition: opacity 250ms ease;
        border-radius: ${variable('border-radius')};
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: ${({ active }) => (active ? ' 10%' : '0%')};
        z-index: 1;
      }

      &:hover,
      &:active {
        &::before {
          opacity: 10%;
        }
      }
    `}

  ${({ active }) => active && activeStyling};

  &:hover {
    ${activeStyling}
  }
`;

export const DeliveryPostalCodeCountryItem = styled.span`
  padding: ${spacer(100)} ${spacer(75)};
  display: grid;
  align-content: center;
  position: relative;

  &:hover {
    background-color: ${color('whisper-200')};
  }
`;

export const StyledPostalCodeSelect = styled(FormGroupInput)`
  input {
    border-radius: 0px ${variable('border-radius')} ${variable('border-radius')} 0px;
  }
`;
