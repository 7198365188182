import Icon from 'components/Icon/Icon';
import styled, { css } from 'styled-components';
import { color, spacer, variable } from 'theme';

interface StyledCountrySelectorProps {
  active: boolean;
  disabled: boolean;
  empty: boolean;
}

export const StyledIcon = styled(Icon)`
  transition: all 250ms ease-in-out;
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1;
`;

const activeStyling = css`
  &::before {
    opacity: 30%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${StyledIcon} {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

export const StyledCountrySelector = styled.div<StyledCountrySelectorProps>`
  cursor: default;
  ${variable('border')};
  border-right: none;
  border-radius: ${variable('border-radius')} 0 0 ${variable('border-radius')};
  background-color: ${color('white')};
  display: grid;
  align-content: center;
  margin-bottom: ${spacer(75)};
  height: calc(100% - ${spacer(75)});

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${color('whisper-200')};
      ${variable('border-right')};
      border-right-color: ${color('whisper-300')};
    `}
  ${({ empty }) =>
    !empty &&
    css<StyledCountrySelectorProps>`
      position: relative;
      cursor: pointer;

      &::before {
        transition: opacity 250ms ease;
        border-radius: ${variable('border-radius')};
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: ${({ active }) => (active ? ' 10%' : '0%')};
        z-index: 1;
      }

      &:hover,
      &:active {
        &::before {
          opacity: 10%;
        }
      }
    `}
    ${({ active }) => active && activeStyling};

  &:hover {
    ${activeStyling}
  }
`;
