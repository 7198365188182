import styled from 'styled-components';
import { color, variable, zIndex } from 'theme';

export const StyledDropdownParent = styled.div`
  position: relative;
  z-index: ${zIndex('base-overwrite')};
  height: 100%;
`;

interface StyledDropdownListProps {
  offset: number;
}

export const StyledDropdownList = styled.div<StyledDropdownListProps>`
  position: absolute;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  max-height: 10em;
  width: 100%;
  background-color: ${color('white')};
  border-radius: ${variable('border-radius')};
  ${variable('border')}
  z-index: 1;
  top: ${({ offset }) => `${offset}px`};
  scroll-behavior: smooth;
  transition: all 150ms ease-in-out;

  &:empty {
    display: none;
  }
`;
