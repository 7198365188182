import { KeyboardEvent, KeyboardEventHandler, memo, MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useOnClickOutside } from 'usehooks-ts';
import { cn } from 'utils/cn';
import { v4 as uuidv4 } from 'uuid';
import useWindowSize from '../../hooks/useWindowSize';
import { removeWhiteSpaces } from '../../utils/textUtil';
import Box from '../Box/Box';
import { StyledDropdownList, StyledDropdownParent } from './Dropdown.styled';

// KeyCodes
const ENTER_KEY_CODE = 13;
const DOWN_ARROW_KEY_CODE = 40;
const UP_ARROW_KEY_CODE = 38;
const ESCAPE_KEY_CODE = 27;
const TAB_KEY_CODE = 9;

// Element selectors
const DROPDOWN_ITEM = 'dropdown-item';
const DROPDOWN_ACTIVE_ITEM = 'dropdown-item-active';

/**
 * Function which gives a unique identifier to all dropdown child elements,
 * This also adds extra attributes for accessibility
 * The returned object has the same structure as the given structure.
 **/

const giveUniqueIdsToItems = <T,>(items: T[]) =>
  items?.map((item) => ({
    element: {
      ...item,
    },
    key: uuidv4(),
  }));

/**
 * The Dropdown component.
 * This component is free from custom styling, making it adjustable for more than one use case. Keep this in mind while working on it.
 * @param {JSX} parent - component the dropdown should be relatively positioned to.
 * @param {Array<JSX>} items - the array of JSX elements. Supplied with custom styling to make dropdown as diverse as possible.
 * @param {Boolean} open - you can control the dropdown using this value, by default it will be shown when data is given.
 * @param {function} onSubmitValue - callback for the onclick on a list item.
 * @param {function} onOpenDropdownCallback - callback when the dropdown opens.
 * @param {function} onCloseDropdownCallback - callback when the dropdown closes.
 * @param {string} id - and id is REQUIRED, this makes the different dropdowns on the page unique.
 * @param {boolean} openOnClick - should show the dropdown onclick, can be useful for non changing data.
 * @param {boolean} disableOutsideClickDetection - disables the outSideClickDetection if you prefer so.
 *
 * @example
 * <Dropdown
 *   id="A unique name for the dropdown NOT just 'dropdown'"
 *   items={{
 *     node: (
 *       <>Any Jsx Element</>
 *     ),
 *     value: 'the value you want receive in the onSubmitValue callback',
 *   }}
 *   onCloseDropdownCallback={() => doSomethingOnClose()}
 *   onSubmitValue={(value) => doSomethingOnSubmit()}
 *   parent={
 *     <>Any Jsx Element, the dropdown will be positioned below this container</>
 *   }
 * />
 */

export type DropdownItem<T = string | undefined> = {
  node: React.ReactNode;
  value: T;
};

interface DropdownProps<T> {
  className?: string;
  disableOutsideClickDetection?: boolean;
  id: string;
  items: DropdownItem<T>[];
  onCloseDropdownCallback?: () => void;
  onOpenDropdownCallback?: () => void;
  onSubmitValue: (value: T) => void;
  openOnClick?: boolean;
  parent: React.ReactNode;
}

const Dropdown = <T,>({
  className,
  disableOutsideClickDetection,
  id,
  items,
  onCloseDropdownCallback,
  onOpenDropdownCallback,
  onSubmitValue,
  openOnClick,
  parent,
}: DropdownProps<T>) => {
  // Unique Selectors
  const U_DROPDOWN_PARENT = `dropdown-parent-${id}`;
  const U_DROPDOWN_LIST = `dropdown-list-${id}`;
  const U_DROPDOWN_ITEM = `dropdown-item-${id}`;
  const U_DROPDOWN_ACTIVE_ITEM = `dropdown-item-active-${id}`;

  const dropdownParentRef = useRef<HTMLDivElement>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [parentHeight, setParentHeight] = useState<number>();
  const [activeElementIndex, setActiveElementIndex] = useState(0);
  const itemsLength = items?.length;

  const { isTablet } = useWindowSize();
  const uniqueItems = giveUniqueIdsToItems(items);

  const handleOutsideClick = () => {
    if (!showDropdown || disableOutsideClickDetection) return;
    toggleDropdown(false);
  };

  useOnClickOutside(dropdownParentRef, handleOutsideClick);

  const toggleDropdown = (open: boolean) => {
    setShowDropdown(open);

    if (open && typeof onOpenDropdownCallback === 'function') {
      onOpenDropdownCallback();
    } else if (!open && typeof onCloseDropdownCallback === 'function') {
      onCloseDropdownCallback();
    }
  };

  useEffect(() => {
    if (!openOnClick) {
      if (!itemsLength && showDropdown) {
        toggleDropdown(false);
      } else if (itemsLength > 0 && !showDropdown) {
        if (itemsLength < activeElementIndex) {
          setActiveElementIndex(0);
        }

        toggleDropdown(true);
      }
    }
  }, [items]);

  useEffect(() => {
    const parentElementHeight = dropdownParentRef?.current?.clientHeight;

    if (parentHeight !== parentElementHeight) setParentHeight(parentElementHeight);
  }, [dropdownParentRef?.current]);

  const handleScrollPositionOfDropdown = (index: number) => {
    const dropdownList = dropdownListRef?.current;
    if (!dropdownList) return;
    const dropdownItems = Array.from(document.getElementsByClassName(DROPDOWN_ITEM)).filter(
      (el) => el instanceof HTMLElement,
    ) as HTMLElement[];
    const activeKey = uniqueItems?.[index]?.key;

    const activeItem = dropdownItems.find((dropdownItem) => dropdownItem.dataset.key === activeKey);

    const offsetScroll = activeElementIndex === 0 ? 0 : activeItem?.clientHeight ?? 0 * 0.5;

    dropdownList.scrollTop = activeItem?.offsetTop ?? 0 - offsetScroll;
  };

  const onSubmit = (index: number, event?: KeyboardEvent<HTMLDivElement>) => {
    const currentlySelectedValue = items?.[index]?.value;

    toggleDropdown(false);

    if (!currentlySelectedValue) return;

    // Prevent form submission if there is a value selected otherwise submit form
    event?.preventDefault();

    if (index !== activeElementIndex) {
      setActiveElementIndex(index);
    }
    onSubmitValue(currentlySelectedValue);
  };

  const handleKeyOptions: KeyboardEventHandler<HTMLDivElement> = (event) => {
    switch (event.keyCode || event.which) {
      case DOWN_ARROW_KEY_CODE:
        // Prevent window scroll when tabbing through items
        event?.preventDefault();

        if (activeElementIndex < uniqueItems.length - 1) {
          setActiveElementIndex(activeElementIndex + 1);
          handleScrollPositionOfDropdown(activeElementIndex + 1);
        }

        break;
      case UP_ARROW_KEY_CODE:
        // Prevent window scroll when tabbing through items
        event?.preventDefault();

        if (activeElementIndex > 0) {
          setActiveElementIndex(activeElementIndex - 1);
          handleScrollPositionOfDropdown(activeElementIndex - 1);
        }

        break;
      case TAB_KEY_CODE:
        if (activeElementIndex < uniqueItems.length) {
          event?.preventDefault();
          setActiveElementIndex(activeElementIndex + 1);
          handleScrollPositionOfDropdown(activeElementIndex + 1);
        }

        break;
      case ESCAPE_KEY_CODE:
        toggleDropdown(false);
        break;
      case ENTER_KEY_CODE: {
        onSubmit(activeElementIndex, event);
        break;
      }
      default:
    }
  };

  return (
    <StyledDropdownParent
      className={cn('dropdown-parent', className)}
      ref={dropdownParentRef}
      id={U_DROPDOWN_PARENT}
      onKeyDown={handleKeyOptions}
      role="button"
      tabIndex={0}
    >
      <Box fullHeight onClick={() => toggleDropdown(!showDropdown)} role="button" tabIndex={0}>
        {parent}
      </Box>

      {showDropdown && (
        <StyledDropdownList
          className="dropdown-list"
          id={U_DROPDOWN_LIST}
          ref={dropdownListRef}
          offset={parentHeight ?? 0}
          onKeyDown={handleKeyOptions}
          role="button"
          tabIndex={0}
        >
          {uniqueItems.map(({ element, key }, index) => {
            const uxProps = {
              onFocus: isTablet ? undefined : () => setActiveElementIndex(index),
              onMouseOver: isTablet
                ? undefined
                : (e: MouseEvent<HTMLDivElement>) => {
                    e.currentTarget.parentElement?.focus();
                    setActiveElementIndex(index);
                  },
              onTouchStart: isTablet
                ? (e: TouchEvent<HTMLDivElement>) => {
                    e.currentTarget.parentElement?.focus();
                  }
                : undefined,
            };

            const isActive = activeElementIndex === index;

            return (
              <div
                key={key}
                className={removeWhiteSpaces(
                  `${U_DROPDOWN_ITEM} ${DROPDOWN_ITEM} ${isActive ? DROPDOWN_ACTIVE_ITEM : ''}`,
                )}
                data-key={key}
                id={activeElementIndex === index ? U_DROPDOWN_ACTIVE_ITEM : undefined}
                onClick={() => onSubmit(index)}
                {...uxProps}
                role="button"
                tabIndex={showDropdown ? 0 : -1}
              >
                {element.node}
              </div>
            );
          })}
        </StyledDropdownList>
      )}
    </StyledDropdownParent>
  );
};

export default memo(Dropdown, isEqual) as typeof Dropdown;
