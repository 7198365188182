import { useEffect, useState } from 'react';
import { useNotificationStore } from 'store/notification';
import { Location } from 'types/Store';
import { NOTIFICATION_TYPES } from '../constants/notifications';
import { createNotification } from '../utils/notifications';

const getCurrentPosition = async () => {
  try {
    if (navigator?.geolocation) {
      const pos: GeolocationPosition = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const {
        coords: { latitude, longitude },
      } = pos;
      const location: Location = { latitude, longitude };
      return location;
    }
  } catch (error) {
    throw new Error();
  }

  return null;
};

export const getGeolocationPosition = async (showWarning?: boolean) => {
  try {
    const location = await getCurrentPosition();
    return location;
  } catch {
    if (showWarning) {
      const notification = createNotification({ type: NOTIFICATION_TYPES.GEO_LOCATION });
      useNotificationStore.getState().actions.queueNotification(notification);
    }
  }
  return null;
};

const useGeoLocation = (showWarning = false) => {
  const [position, setPosition] = useState<Location | null>(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const location = await getGeolocationPosition(showWarning);
        setPosition(location);
      } catch (error) {
        console.error('Error fetching geolocation position:', error);
      }
    };

    fetchLocation();
  }, []);

  return position;
};

export default useGeoLocation;
