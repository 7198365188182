import FormGroupInput from 'components/Form/FormGroupInput/FormGroupInput';
import styled from 'styled-components';
import { color, spacer, variable } from 'theme';

export const StyledPostalCodeItem = styled.span`
  padding: ${spacer(100)};
  display: block;

  .dropdown-item-active & {
    background-color: ${color('primary-400')};
    color: ${color('white')};
  }
`;

export const StyledPostalCodeSelect = styled(FormGroupInput)`
  input {
    border-radius: 0px ${variable('border-radius')} ${variable('border-radius')} 0px;
  }
`;
