import styled from 'styled-components';
import { color, spacer } from 'theme';

export const StyledCountryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacer(50)};
  padding: 0px ${spacer(75)};
  height: 100%;

  &:hover {
    background-color: ${color('whisper-200')};
  }
`;
