import Dropdown, { DropdownItem } from 'components/Dropdown/Dropdown';
import { ICON_NAMES } from 'constants/icon';
import { CountryCode } from 'constants/store';
import { Country } from 'types/Address';
import CountryItem from '../CountryItem/CountryItem';
import { StyledCountrySelector, StyledIcon } from './CountrySelector.styled';

const COUNTRY_DROPDOWN_ID = 'country-dropdown';

const getCountryDropdownItems = (countries: Country[], activeCountry?: Country, showIsoCode?: boolean) =>
  countries.reduce((acc: DropdownItem<CountryCode>[], country) => {
    if (country.isocode && country?.isocode !== activeCountry?.isocode) {
      acc.push({
        node: <CountryItem country={country} showIsoCode={showIsoCode} />,
        value: country.isocode,
      });
    }
    return acc;
  }, []);

interface CountrySelectorProps {
  availableCountries: Country[];
  className?: string;
  disabled: boolean;
  id: string;
  isChoosingCountry: boolean;
  selectedCountry?: Country;
  setIsChoosingCountry: (isChoosingCountry: boolean) => void;
  setNewCountry: (country: Country) => void;
  showCountryIsoCode?: boolean;
}

const CountrySelector = ({
  availableCountries,
  className,
  disabled,
  id,
  isChoosingCountry,
  selectedCountry,
  setIsChoosingCountry,
  setNewCountry,
  showCountryIsoCode,
}: CountrySelectorProps) => {
  const dropdownCountryItems = getCountryDropdownItems(availableCountries, selectedCountry, showCountryIsoCode);
  const hasRemainingItems = dropdownCountryItems?.length > 0;

  const onSubmitValue = (value: CountryCode) => {
    if (disabled) return;
    const newCountry = availableCountries.find(({ isocode }) => isocode === value);
    newCountry && setNewCountry(newCountry);
  };

  return (
    <Dropdown
      id={`${COUNTRY_DROPDOWN_ID}_${id}`}
      items={dropdownCountryItems}
      onCloseDropdownCallback={() => setIsChoosingCountry(false)}
      onOpenDropdownCallback={() => setIsChoosingCountry(true)}
      onSubmitValue={onSubmitValue}
      openOnClick
      parent={
        <StyledCountrySelector
          className={className}
          active={isChoosingCountry}
          disabled={disabled}
          empty={!hasRemainingItems}
        >
          {selectedCountry && <CountryItem country={selectedCountry} showIsoCode={showCountryIsoCode} />}
          {hasRemainingItems && <StyledIcon type="custom" color="white" name={ICON_NAMES.CHEVRON} size={150} />}
        </StyledCountrySelector>
      }
    />
  );
};

export default CountrySelector;
