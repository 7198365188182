import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { postalCodeKeys } from '.';
import { fetchCountries, fetchPostalCodeLocations } from './connector';

const useCountries = (enabled = true) => {
  const { locale } = useRouter();
  return useQuery({
    enabled,
    queryFn: () => fetchCountries({ locale }),
    queryKey: postalCodeKeys.countries({ locale }),
    select: (data) => data?.countries || [],
  });
};

type UsePostalCodeLocationsParams = {
  borderZoneOnly?: boolean;
  countryIsoCode?: string;
  enabled?: boolean;
  searchTerm?: string;
};

const usePostalCodeLocations = ({
  borderZoneOnly = false,
  countryIsoCode,
  enabled = true,
  searchTerm,
}: UsePostalCodeLocationsParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled,
    queryFn: () => fetchPostalCodeLocations({ borderZoneOnly, countryIsoCode, locale, searchTerm }),
    queryKey: postalCodeKeys.postalCodeLocations({ borderZoneOnly, countryIsoCode, locale, searchTerm }),
    select: (data) => data?.postalCodes || [],
  });
};

export { useCountries, usePostalCodeLocations };
