import Image from 'components/Image/Image';
import Typography from 'components/Typography/Typography';
import { Country } from 'types/Address';
import { StyledCountryItem } from './CountryItem.styled';

interface CountryFlagProps {
  country: Country;
  showIsoCode?: boolean;
}

const CountryItem = ({ country, showIsoCode }: CountryFlagProps) => {
  if (!country) {
    return null;
  }

  return (
    <StyledCountryItem>
      {country?.flag?.url && <Image alt={country?.name} height={40} width={40} src={country.flag.url} />}
      {showIsoCode && country?.isocode && <Typography textAlign="center">{country.isocode}</Typography>}
    </StyledCountryItem>
  );
};

export default CountryItem;
